.pageContainer {
  height: auto;
  min-height: calc(100vh - 20vh - 80px);
  width: 100vw;
  color: #2a2a2a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageContent {
  height: 100%;
  width: 100%;
  max-width: 2000px;
  padding: 60px 120px;
  line-height: 1.5;
}

.pageContent h1 {
  font-size: 3rem;
}
.pageContent h2 {
  font-size: 2rem;
  color: #4361ee;
  font-weight: 500;
  margin-bottom: 10px;
}
.pageContent h3 {
  font-size: 1.7rem;
  color: #2a2a2a;
  font-weight: 400;
  margin-top: 10px;
}

.pageContent h4 {
  font-size: 1.3rem;
  font-weight: 100;
  color: #2a2a2a;
}

.pageContent p {
  margin-left: 20px;
  font-size: 1.2rem;
  color: #474747;
}

.pageContent li {
  margin-left: 40px;
  font-size: 1.2rem;
  color: #474747;
}

.pageContent a {
  font-size: 1.2rem;
  color: #4361ee;
  font-weight: 500;
}

.pageContent h2 a {
  font-size: 2rem;
}

@media screen and (min-width: 2000px) {
  .pageContainer {
    min-height: calc(100vh - 20vh - 90px);
  }
}

@media screen and (max-width: 950px) {
  .pageContent {
    width: 100vw;
    padding: 30px 30px;

    flex-direction: column-reverse;
    justify-content: center;
    overflow-wrap: break-word;
  }

  .pageContent h1 {
    font-size: 2.5rem;
  }
  .pageContent h2 {
    font-size: 1.5rem;
  }
  .pageContent h3 {
    font-size: 1.4rem;
  }
  .pageContainer h4 {
    font-size: 1.2rem;
  }
  .pageContent p,
  .pageContent a,
  .pageContent h2 a,
  .pageContent li {
    font-size: 1.1rem;
  }
}
