.portfolioContainer {
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  color: #2a2a2a;
}

.portfolioContent {
  height: 100%;
  width: 100%;
  max-width: 2000px;
  padding: 40px 120px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
}

.portfolioTop {
  width: 100%;
}

.portfolioTitle {
  font-size: 1.85rem;
}

.projects,
.skills,
.experience {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.portfolioContent h2 {
  font-size: 2.3rem;
  color: #474747;
}

.portfolioContent h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #474747;
}

.projectGrid {
  gap: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
  margin-bottom: 25px;
}

.experienceGrid {
  gap: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
  margin-bottom: 25px;
}

.itemList {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

@media screen and (min-width: 2000px) {
  .portfolioTop h1 {
    font-size: 5rem;
  }
  .portfolioContent h2 {
    font-size: 3rem;
  }
  .portfolioContent h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1150px) {
  .projectGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .experienceGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 950px) {
  .portfolioContent {
    width: 100vw;
    padding: 20px 30px;
  }
  .portfolioTitle h1 {
    font-size: 2.5rem;
  }

  .portfolioContent h2 {
    font-size: 1.5rem;
  }

  .portfolioContent h3 {
    font-size: 1.4rem;
  }
  .projectGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .experienceGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
